import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Cookies from 'js-cookie'
import Alert from 'react-s-alert'
import { space } from 'theme'
import { getTimeZone } from 'helpers'
import request from 'utils/request'
import GoogleButton from 'components/molecules/GoogleButton'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import { getFPTrefid, getFPTid } from '../../../plans'

const StyledSignInWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  margin: ${space.m} 0;
  width: 100%;
`

const StyledBorder = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.border_color};
  box-sizing: border-box;
  height: 1px;
  margin: 0;
  width: 40%;
`

const GoogleLogin = ({ text, borderText, plan } = {}) => {
  const [isSigningIn, setIsSigningIn] = useState(false)
  const [isGoogleSigninEnabled, setIsGoogleSigninEnabled] = useState(true)
  const via_redirect = false

  useEffect(() => {
    setIsSigningIn(false)
    // setIsGoogleSigninEnabled(typeof google !== 'undefined')
  }, [])

  const signInWithGoogle = () => {
    setIsSigningIn(true)
    const handleCredentialResponse = async (response) => {
      if (response && response.access_token) {
        response.timezone = getTimeZone()
        response.plan = plan

        response.fp_id = getFPTrefid()
        response.fp_tid = getFPTid()

        try {
          const activity = localStorage.getItem('activity')

          if (activity) {
            response.activity = activity
          }
        } catch (error) {}

        try {
          const fields = [
            'utm_source',
            'utm_medium',
            'utm_campaign',
            'referrer',
            '__gtm_campaign_url',
            '__gtm_referrer',
          ]

          fields.forEach((name) => {
            const value = Cookies.get(name)

            if (value) {
              response[name] = decodeURIComponent(value)
            }
          })
        } catch (error) {}

        const res = await request({
          path: 'connect/google/token',
          method: 'POST',
          body: response,
        })

        if (res.token) {
          await Cookies.set('jwt', res.token, { secure: true })

          // try {
          //   window.amplitude.setUserId(res.id)
          //   window.amplitude.track('Trial', { plan })
          // } catch (error) {}

          // try {
          //   window.plausible('Signup', { props: { plan, user_id: res.id } })
          // } catch (error) {}

          document.location = res.redirect || '/dashboard'
        }
      } else {
        Alert.error(`Whoops! Unable to sign in 😞`)
      }
    }

    const tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
      scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
      prompt: 'select_account',
      callback: handleCredentialResponse,
    })

    tokenClient.requestAccessToken()
    setIsSigningIn(false)
  }

  return isGoogleSigninEnabled ? (
    <Flex flexDirection="column">
      <StyledSignInWrapper>
        <StyledBorder />
        <Text>{borderText}</Text>
        <StyledBorder />
      </StyledSignInWrapper>
      <GoogleButton
        onClick={() => {
          if (!isSigningIn) {
            if (via_redirect) {
              const timezone = getTimeZone()
              document.location = `/api/connect/google?timezone=${timezone}&plan=${plan}`
            } else {
              signInWithGoogle()
            }
          }
        }}
        text={text}
      />
    </Flex>
  ) : null
}

GoogleLogin.defaultProps = {
  text: 'Sign in with Google',
  borderText: 'or',
}

GoogleLogin.propTypes = {
  text: PropTypes.string,
  borderText: PropTypes.string,
}

export default GoogleLogin
